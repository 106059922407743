import { CSSObject } from "@emotion/react";
import { forwardRef } from "react";

// import { COLORS } from "../../../styles/colors";

export interface ITextProps {
  commonStyles?: CSSObject;
  children: React.ReactNode;
  onClick?: () => void;
}

const Text = forwardRef<HTMLSpanElement, ITextProps>(
  ({ commonStyles, children, ...props }, ref) => (
    <span
      ref={ref}
      css={{
        //fontSize: "0.875rem",
        //color: COLORS.black1,
        whiteSpace: "pre-wrap",
        ...commonStyles,
      }}
      {...props}
    >
      {children}
    </span>
  ),
);

Text.displayName = "Text";

export default Text;
