export const COLORS = {
  white: "#ffffff",
  black: "#000000",
  black1: "#2D2D2D",
  black2: "#4C4C4C",
  blue1: "#007aff",
  blue2: "#F0F8FF",
  blue3: "#116f9a",
  blue4: "#1B3668",
  blue5: "#116F9A",
  blue6: "#0D587B",
  blue7: "#408BAE",
  blue8: "#E6EFEF",
  blue9: "#99BEC0",
  blue10: "#116f9a4d",
  blue11: "#70a8c2",
  blue12: "#669da133",
  blue13: "#669da199",
  blue14: "#6F9EB3",
  blue15: "#5D739B",
  blue16: "#CCDEDF",
  blue17: "#116F9A",
  grey: "#cccccc",
  grey1: "#F5F5F5",
  grey2: "#808285",
  grey3: "#708090",
  grey4: "#808080",
  grey5: "#ddd",
  grey6: "#F7F7F7",
  grey7: "#D7D7D7",
  grey8: "#D8D8D8",
  grey9: "#979797",
  grey10: "#EDEDED",
  grey11: "#E4E5E5",
  grey12: "#F1F1F1",
  grey13: "#C0B498",
  grey14: "#C8CED3",
  grey15: "#4C4C4C80",
  grey16: "#8E8E9380",
  grey17: "#e0e0e0cc",
  grey18: "#979797",
  grey19: "#C6C2C1",
  grey20: "#F5F5F5",
  grey21: "#CCCDCE",
  wathet: "#f0f8ff",
  wathet1: "#f0f0f1",
  green3: "#005D63",
  yellow: "#FEEF99",
  pink1: "#ECA599",
  red1: "#C74141",
  red2: "#FAE9E5",
  red3: "#D11F00",
  red4: "#e37866",
  red5: "#DE4B64",
  jade: "#C5D9CF",
};
