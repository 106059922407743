/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Spinner, notify, Text } from "../../../components";
import CommonModal from "../CommonModal";
import { FONT_WEIGHT, fontWeight } from "../../../styles/fonts";
import { COLORS } from "styles/colors";

interface IDisplayModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  displayQuery: string | "";
  setIsInit: (inIt: boolean) => void;
}

export interface ICreateFolderForm extends FieldValues {
  displayQuery: string;
}

const DisplayModal = ({
  isOpen,
  setIsOpen,
  displayQuery,
  setIsInit,
}: IDisplayModalProps) => {
  const navigate = useNavigate();

  //const [folderStage, setFolderStage] = useState("");
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  //const { authorizedToken } = JSON.parse(useContext(AppToken));
  // const { handleSubmit, control } = useForm<ICreateFolderForm>({
  //   defaultValues: {
  //     folderName: '',
  //   },
  // });

  const defaultValues = {
    displayQuery: "",
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateFolderForm>({
    defaultValues: defaultValues,
    mode: "onBlur",
  });

  const onSubmit: SubmitHandler<ICreateFolderForm> = async (data) => {
    setIsShowSpinner(true);
    const { errorMessage, response } = { response: "", errorMessage: "" };
    // await createFolder(
    //   {
    //     folderId: folderId && folderId > 0 ? folderId : null,
    //     folderName: data.folderName,
    //   },
    //   authorizedToken,
    // );
    if (response) {
      setIsOpen(false);
      setIsShowSpinner(false);
      notify({
        message: response,
        type: "success",
      });
    }

    if (errorMessage) {
      setIsShowSpinner(false);
      notify({
        message: errorMessage,
        type: "error",
      });
    }
    setIsInit(true);
    navigate("/", { replace: true });
  };

  useEffect(() => {
    if (!displayQuery) {
      //setFolderStage("ADD");
      reset({
        displayQuery: "",
      });
    } else {
      //setFolderStage("VIEW");
      reset({
        displayQuery,
      });
    }
  }, [displayQuery, isOpen]);

  const formatSqlQuery = (query: string) => {
    return query.split("\\n").map((segment, index) => (
      <>
        {index > 0 && <br />}
        {segment}
      </>
    ));
  };

  return (
    <>
      {isShowSpinner ? (
        <Spinner
          commonStyles={{
            alignItems: "center",
            flex: 1,
          }}
        />
      ) : null}

      <CommonModal isOpen={isOpen} title={`Display SQL`} setIsOpen={setIsOpen}>
        <div
          style={{
            marginTop: "1.5rem",
            display: "flex",
            flexDirection: "row",
            fontSize: "0.875rem",
            color: COLORS.black1,
            fontWeight: FONT_WEIGHT.regular,
          }}
        >
          {formatSqlQuery(displayQuery)}
        </div>
      </CommonModal>
    </>
  );
};

export default DisplayModal;
