/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

import { Select, Spinner, notify } from "../../../components";
import CommonModal from "../CommonModal";
import { AppInterval } from "App";
import { DropDownSourceType } from "types/api";

interface IRefreshModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export interface IRefreshModalForm extends FieldValues {
  frequencyType: string;
}

const RefreshModal = ({ isOpen, setIsOpen }: IRefreshModalProps) => {
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const { frequencyInterval, setFrequencyInterval } = useContext(AppInterval);
  const [frequency, setFrequency] = useState(
    frequencyInterval.frequencyInterval,
  );
  const [formSelectFields, setFormSelectFields] =
    useState<DropDownSourceType>();
  const defaultValues = {
    frequencyType: frequencyInterval.frequencyInterval,
  };

  const {
    //register,
    //reset,
    handleSubmit,
    //formState: {},
  } = useForm<IRefreshModalForm>({
    defaultValues: defaultValues,
    mode: "onBlur",
  });

  const onSubmit: SubmitHandler<IRefreshModalForm> = async () => {
    setIsShowSpinner(true);
    setFrequencyInterval({
      frequencyInterval: frequency,
      tabName: frequencyInterval.tabName,
    });
    setIsOpen(false);
    setIsShowSpinner(false);
    notify({
      message: "Set successfully",
      type: "success",
    });
  };

  useEffect(() => {
    setFormSelectFields({
      interval: [
        { name: "None", value: "0" },
        { name: "10 Seconds", value: "10" },
        { name: "30 Seconds", value: "30" },
        { name: "1 Minute", value: "60" },
        { name: "3 Minutes", value: "180" },
        { name: "5 Minutes", value: "300" },
      ],
    });
  }, []);

  function statusChangeHandler(interval: string) {
    setFrequency(interval);
  }

  return (
    <>
      {isShowSpinner ? (
        <Spinner
          commonStyles={{
            alignItems: "center",
            flex: 1,
          }}
        />
      ) : null}

      <CommonModal
        isOpen={isOpen}
        title={`Set Auto-Refresh`}
        setIsOpen={setIsOpen}
        onSubmit={handleSubmit(onSubmit)}
        submitText="Set"
      >
        <div
          style={{
            marginTop: "1.5rem",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <span>
              <Select
                label="Auto-refresh Interval"
                dataSource={formSelectFields?.interval || []}
                onSingleSelect={(val) => statusChangeHandler(val)}
                settings={{ selectAll: false }}
                singleSelectValue={frequency || "0"}
              />
            </span>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default RefreshModal;
