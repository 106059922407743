import { CSSObject } from "@emotion/react";

import Text from "../Text";
import { COLORS } from "../../../styles/colors";
import { FONT_WEIGHT } from "../../../styles/fonts";

const CheckBox2 = ({
  name,
  label,
  defaultChecked,
  customStyles,
  onChange,
}: {
  name: string;
  label: string;
  defaultChecked?: boolean;
  customStyles?: CSSObject;
  onChange?(event: React.FormEvent<unknown>): void;
}) => {
  return (
    <span
      style={{
        fontSize: "0.875rem",
        color: COLORS.black1,
        whiteSpace: "pre-wrap",
      }}
      onClick={onChange}
      onKeyDown={onChange}
      role="button"
      tabIndex={0}
    >
      <input
        type="checkbox"
        id={name}
        checked={defaultChecked}
        onChange={onChange}
        value={label}
        title={label}
        css="checkmark"
      />
      <Text
        commonStyles={{
          fontWeight: FONT_WEIGHT.regular,
          color: COLORS.black1,
          fontSize: "0.875rem",
        }}
      >
        {label}
      </Text>
    </span>
  );
};

export default CheckBox2;
