import { useEffect, useState } from "react";

export const DateTime = () => {
  const [date, setDate] = useState(new Date());
  // const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);
    // setIsClient(true);
    return function cleanup() {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      {" "}
      {date.toLocaleDateString()}, {date.toLocaleTimeString()}
      {" HKT"}
    </>
  );
};

export default DateTime;
