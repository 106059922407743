/** @jsxImportSource @emotion/react */
import { FormEvent } from "react";
import Modal from "react-modal";

import { ReactComponent as CloseIcon } from "../../../assets/images/close.svg";
import { Button, Line, Text } from "../../../components";
import { COLORS } from "../../../styles/colors";
import { fontWeight } from "../../../styles/fonts";

const CancelButton = ({ onClick }: { onClick: () => void }) => (
  <div role="presentation" css={{ cursor: "pointer" }} onClick={onClick}>
    <Text
      commonStyles={{
        color: COLORS.blue1,
        fontWeight: fontWeight.bold,
      }}
    >
      Cancel
    </Text>
  </div>
);

interface ICommonModalProps {
  isOpen: boolean;
  title: string;
  buttonId?: string;
  modalId?: string;
  setIsOpen: (isOpen: boolean) => void;
  children?: React.ReactNode;
  onSubmit?: (e: React.FormEvent) => void;
  submitText?: string;
  isSave?: boolean;
  isScroll?: boolean;
}

const CommonModal = ({
  buttonId,
  modalId,
  isOpen,
  title,
  children,
  setIsOpen,
  onSubmit,
  submitText,
  isSave = false,
  isScroll = false,
}: ICommonModalProps) => {
  Modal.setAppElement("#root");

  const customStyles = {
    content: {
      margin: "-5rem",
      background: "hsla(0, 0%, 30%, 0.4)",
      border: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backdropFilter: "blur(1px)",
    },
    overlay: { zIndex: 1000 },
  };

  const modalOpened = () => {
    document.body.style.overflowY = "hidden";
  };

  const modalClosed = () => {
    document.body.style.overflowY = "scroll";
  };

  return (
    <div role="presentation" onClick={(e) => e.stopPropagation()}>
      <Modal
        isOpen={isOpen}
        style={customStyles}
        onAfterOpen={modalOpened}
        onAfterClose={modalClosed}
      >
        <div
          id={modalId}
          css={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            background: COLORS.white,
            borderRadius: "1.0rem",
            padding: "1.75rem  1.75rem 1rem 1.75rem",
            maxHeight: "42.5rem",
            maxWidth: "75rem",
            overflow: "hidden",
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "1.5rem",
              borderColor: COLORS.grey13,
            }}
          >
            <Text
              commonStyles={{
                fontSize: "1.875rem",
                fontWeight: fontWeight.bold,
                color: COLORS.blue1,
              }}
            >
              {title}
            </Text>
            <button
              type="button"
              css={{ backgroundColor: "transparent", border: "none" }}
              onClick={() => setIsOpen(false)}
              aria-label="Close"
            >
              {/* <Svg icon="close" /> */}
              <CloseIcon />
            </button>
          </div>

          {onSubmit && submitText ? (
            <form
              style={{ width: "50rem", height: "20rem" }}
              onSubmit={onSubmit}
            >
              <Line />
              {isScroll ? (
                <div
                  style={{
                    overflow: "auto",
                    maxHeight: "30rem",
                    paddingRight: "1rem",
                  }}
                >
                  {children}
                </div>
              ) : (
                children
              )}
              <Line commonStyles={{ marginTop: "1.5rem" }} />
              <div
                css={{
                  display: "flex",
                  padding: "1.5rem 1rem 0.5rem 0",
                  justifyContent: "end",
                  alignItems: "center",
                  position: "sticky",
                }}
              >
                <CancelButton
                  onClick={() => {
                    setIsOpen(false);
                  }}
                />
                <Button
                  type={isSave ? undefined : "submit"}
                  id={buttonId}
                  variant={isSave ? "secondary" : undefined}
                  css={{ marginLeft: "1.25rem" }}
                  title={submitText}
                  onClick={
                    isSave ? (e: FormEvent<Element>) => onSubmit(e) : undefined
                  }
                />
                {isSave && (
                  <Button
                    id="create"
                    type="submit"
                    css={{ marginLeft: "1.25rem" }}
                    title="Set"
                  />
                )}
              </div>
            </form>
          ) : (
            <>
              <Line />
              <div
                css={{
                  overflow: "auto",
                  maxHeight: "30rem",
                  paddingRight: "1rem",
                }}
              >
                {children}
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CommonModal;
