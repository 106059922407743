import { useMsal } from "@azure/msal-react";
import { useEffect, useRef, useState } from "react";
import { AccountInfo } from "@azure/msal-browser";
import { COLORS } from "../../../styles/colors";
import { FONT_WEIGHT } from "../../../styles/fonts";
import { Line, Text } from "components";
import { ReactComponent as CxLogo } from "../../../assets/images/logo.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/images/user.svg";
import RefreshModal from "../RefreshModel";

const Logout = ({ account }: { account: AccountInfo | null }) => {
  const { instance } = useMsal();
  const handleLogout = () => {
    const logoutUrl = `https://login.microsoftonline.com/${account?.tenantId}`;
    const currentAccount = instance.getAccountByHomeId(
      `${account?.homeAccountId}`,
    );
    instance.logoutRedirect({
      account: currentAccount,
      authority: logoutUrl,
      postLogoutRedirectUri: `${window.location.origin}`,
    });
  };

  return (
    <div
      style={{
        minWidth: "16.875rem",
        position: "absolute",
        top: "3.75rem",
        right: "0.75rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        border: "0.0625rem solid",
        padding: "0.8rem",
        borderColor: COLORS.grey1,
        borderRadius: "0.5rem",
        backgroundColor: COLORS.white,
        transition: "all .3s ease-in-out",
        boxShadow: `0rem 0rem 0.5rem ${COLORS.black}`,
      }}
    >
      <Text
        commonStyles={{
          fontSize: "1.0rem",
          fontWeight: FONT_WEIGHT.light,
          paddingBottom: "0.2rem",
        }}
      >
        {account?.name}
      </Text>
      <Line
        commonStyles={{
          marginTop: "0.875rem",
          marginBottom: "0.8rem",
        }}
      />
      <div role="presentation" onClick={() => handleLogout()}>
        <span
          style={{
            fontSize: "0.875rem",
            //color: COLORS.black1,
            whiteSpace: "pre-wrap",
            fontWeight: FONT_WEIGHT.medium,
            color: COLORS.blue1,
            paddingBottom: "0.5rem",
            cursor: "pointer",
          }}
        >
          <Text>Logout</Text>
        </span>
      </div>
    </div>
  );
};

export const Header = ({ account }: { account: AccountInfo | null }) => {
  const refLogout = useRef<HTMLDivElement>(null);

  const [isShowLogout, setIsShowLogout] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openPopup = () => setIsModalOpen(true);

  const handleClickOutside = () => {
    setIsShowLogout(false);
  };
  useEffect(() => {
    const onClickOutsideEvent = (event: MouseEvent) => {
      if (
        isShowLogout &&
        !(
          refLogout.current && refLogout.current?.contains(event.target as Node)
        )
      ) {
        handleClickOutside();
      }
    };

    document.addEventListener("mousedown", onClickOutsideEvent);

    return () => {
      document.removeEventListener("mousedown", onClickOutsideEvent);
    };
  }, [isShowLogout]);

  return (
    <div
      style={{
        top: "0rem",
        width: "100%",
        background: COLORS.white,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1rem",
        borderBottom: `0.0625rem solid ${COLORS.grey}`,
        position: "fixed",
        zIndex: "999",
      }}
    >
      <div
        style={{
          justifyContent: "start",
          left: "0.75rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CxLogo
          style={{
            cursor: "pointer",
            marginBottom: "0.2rem",
          }}
        />
        <span
          style={{
            fontSize: "1.0625rem",
            whiteSpace: "pre-wrap",
            fontWeight: FONT_WEIGHT.medium,
            cursor: "pointer",
            paddingLeft: "1.5rem",
            textAlign: "center",
            color: COLORS.black2,
          }}
        >
          <Text>Task Server Support Tool</Text>
        </span>
        <span
          style={{
            marginLeft: "0.675rem",
            cursor: "pointer",
            color: COLORS.blue17,
            fontSize: "0.9rem",
            fontWeight: FONT_WEIGHT.medium,
            textDecoration: "underline",
          }}
          onClick={openPopup}
          role="presentation"
        >
          Auto-Refresh
          {isModalOpen && <RefreshModal isOpen setIsOpen={setIsModalOpen} />}
        </span>
      </div>
      <div
        role="presentation"
        onClick={() => {
          setIsShowLogout(true);
        }}
        style={{
          position: "absolute",
          right: "0.75rem",
          cursor: "pointer",
          padding: "0.35rem",
          justifyContent: "end",
          display: "flex",
          alignItems: "center",
          backgroundColor: "transparent",
          borderColor: "transparent",
          // ":hover": {
          //   transition: "all 0.2s ease-in-out",
          // },
        }}
      >
        <ProfileIcon />
        <span
          style={{
            fontSize: "1.0rem",
            //color: COLORS.black1,
            whiteSpace: "pre-wrap",
            fontWeight: FONT_WEIGHT.medium,
            color: COLORS.black2,
            paddingLeft: "0.5rem",
            cursor: "pointer",
          }}
        >
          <Text>{account?.name}</Text>
        </span>
      </div>

      {isShowLogout ? (
        <div ref={refLogout}>
          <Logout account={account} />
        </div>
      ) : null}
    </div>
  );
};

export default Header;
